import { Helmet } from "react-helmet";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import RegisterBox from "../components/RegisterBox";

import { useTranslation } from "react-i18next";

const Register = () => {
  const {t}=useTranslation()
  return (
    <div>
      <Helmet>
        <title>{t("registerHead")}</title>
      </Helmet>
      <Navbar />
      <RegisterBox />
      <Footer />
    </div>
  );
};

export default Register;
