import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import api from "../api";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getUsersListFunc, pupOpen } from "../redux/MainReducer";
import PopUpForChangePass from "./modals/PopUpForChangePass";

const LoginBox = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [psw, setPsw] = useState("");
  const [spanErr, setSpanErr] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch();

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const emailChange = (e) => {
    setEmail(e.target.value);
  };

  const pswChange = (e) => {
    setPsw(e.target.value);
  };

  const logsub = async (e) => {
    e.preventDefault();
    setSpanErr("");

    const data = {
      email: email,
      password: psw,
    };
    await api
      .post("/account/login/", data)
      .then((resp) => {
        if (resp.status === 200) {
          const userAuth = {
            email: resp.data.email,
            id: resp.data.id,
          };
          localStorage.setItem("userAuth", JSON.stringify(userAuth));
          localStorage.setItem("ACCESS_TOKEN", resp.data.access);
          localStorage.setItem("REFRESH_TOKEN", resp.data.refresh);
          navigate("/");
        }
      })
      .catch((err) => {
        if (
          err.response.data.detail ===
          "No active account found with the given credentials"
        ) {
          setSpanErr(t("logErr2"));
        } else if (err.response.data.detail === "Incorrect password") {
          setSpanErr(t("logErr1"));
        }
      });
  };

  const popUpValue = useSelector((state) => state.Data.popUpValue);

  return (
    <div className="login_register_container">
      {isLoading ? (
        <div className="lds-dual-ring"></div>
      ) : (
        <div className="login_box">
          <h1 className="login_header">{t("login")}</h1>
          <form onSubmit={logsub}>
            <div>
              <label htmlFor="email">
                <input
                  id="email"
                  value={email}
                  onChange={emailChange}
                  type="email"
                  placeholder={t("email")}
                  required
                />
              </label>
            </div>
            <div>
              <label htmlFor="password" className="eye_psw">
                <input
                  id="password"
                  value={psw}
                  onChange={pswChange}
                  type={showPassword ? "text" : "password"}
                  placeholder={t("passw")}
                  required
                />
                <i
                  className={`fa-regular fa-eye${showPassword ? "-slash" : ""}`}
                  onClick={toggleShowPassword}
                ></i>
              </label>
              <span className="error">{spanErr}</span>
            </div>
            <button style={{ cursor: "pointer" }}>{t("login")}</button>
            <span className="go_to_register">
              {t("ifNoAcc")}
              <Link to="/register" className="link_text">
                {t("goRegistr")}
              </Link>
            </span>
            <span className="go_to_register"></span>
          </form>
        </div>
      )}
      <button
        className="forget_password_question_btn"
        onClick={() => dispatch(pupOpen())}
      >
        {" "}
        Şifrəni unutmusunuz ?
      </button>
      {popUpValue && <PopUpForChangePass />}
    </div>
  );
};

export default LoginBox;
