import { Routes, Route, BrowserRouter } from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Basket from "./pages/Basket";
import Product from "./pages/Product";
import ContactUs from "./pages/ContactUs";
import Payment from "./components/Payment";
import Favourites from "./pages/Favourites";
import AddressPayment from "./components/AddressPayment";
import Myorders from "./pages/Myorders";
import ForgetPassword from "./pages/ForgetPassword";
import Approved from "./pages/Approved";
import Canceled from "./pages/Canceled";
import Declined from "./pages/Declined";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getToken } from "./action/MainAction";
// import { useEffect } from "react";
// import { baseUrl } from "./base_url";
// import axios from "axios";

export default function App() { 
  const dispatch=useDispatch()

  const {token}=useSelector(state=>state.Data)
  // useEffect(() => {
  //   const fetchData = async () => {
  //     axios.get(`${baseUrl}/tailor/payment-result/`,{
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`, 
  //       }
  //     })
  //     .then(resp=>{
  //       console.log(resp);
  //     })
  //   };

  //   fetchData();
  // }, []);
  useEffect(()=>{
    dispatch(getToken())
  },[dispatch])
  return (
    <BrowserRouter basename="/"> 
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        {/* forget-password */}
        <Route path="/forget-password/:uid/:token/" element={<ForgetPassword />} />

        <Route path="/register" element={<Register />} />
        <Route path="/basket" element={<Basket />} />
        <Route path="/product/:id" element={<Product />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/myfavourites" element={<Favourites />} />
        <Route path="/myorders" element={<Myorders />} />
        <Route path="/basket/payment" element={<AddressPayment />} />
        <Route path="/payment-result/approved/:token" element={<Approved />} />
        <Route path="/payment-result/canceled/:token" element={<Canceled />} />
        <Route path="/payment-result/declined/:token" element={<Declined />} />
        <Route path="*" element={<Login />} />
      </Routes>
    </BrowserRouter>
  );
}
