import { useSelector } from "react-redux";
import BasketBox from "../components/BasketBox";
import CountDesk from "../components/CountDesk";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

const Basket = () => {
  const basket = useSelector((state) => state.Data.basket);
  const { t } = useTranslation();

  return (
    <div>
      <Helmet>
        <title>{t("basketHead")}</title>
      </Helmet>
      <Navbar />
      <div className="b_container">
        <h2>{t("headBas")}</h2>
        {basket.length !== 0 ? (
          <div className="basket_box">
            <div className="basket_colum_orders">
              <BasketBox />
            </div>
            <CountDesk />
          </div>
        ) : (
          <div className="basket_box">
            <div className="basket_colum_orders">
              <span style={{ fontSize: "2rem" }}>{t("ifNoPro")}.</span>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Basket;
