import React, { useEffect, useState } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { useDispatch, useSelector } from "react-redux";
import { getMyOrderID } from "../action/MainAction";
import PaymentDesk from "./PaymentDesk";
import axios from "axios";
import VisitCartOfUser from "./VisitCartOfUser";
import { Navigate, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { baseUrl } from "../base_url";
import Loading from "./modals/Loading";

const AddressPayment = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  const [nameValue, setNameValue] = useState("");
  const [lastNameValue, setLastNameValue] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [numberValue, setNumberValue] = useState("");
  const [addressValue, setAddressValue] = useState("");
  const [noteValue, setNoteValue] = useState("");

  const loggedInUser = useSelector((state) => state.Data.loggedInUser);
  const [isLoading1, setIsLoading1] = useState(true);

  useEffect(() => {
    dispatch(getMyOrderID())
      .then(() => setIsLoading1(false))
      .catch(() => setIsLoading1(false));

    if (loggedInUser) {
      setNameValue(loggedInUser.first_name || "");
      setLastNameValue(loggedInUser.last_name || "");
      setEmailValue(loggedInUser.email || "");
      setNumberValue(loggedInUser.phone_number || "");
      setAddressValue("");
    }
  }, [dispatch, loggedInUser]);

  const myOrderIDvalue = useSelector((state) => state.Data.myOrderIDvalue);

  const nameChange = (e) => {
    setNameValue(e.target.value);
  };
  const lNameChange = (e) => {
    setLastNameValue(e.target.value);
  };
  const emailChange = (e) => {
    setEmailValue(e.target.value);
  };
  const numberChange = (e) => {
    setNumberValue(e.target.value);
  };
  const adressChange = (e) => {
    setAddressValue(e.target.value);
  };
  const noteChange = (e) => {
    setNoteValue(e.target.value);
  };

  if (!localStorage.getItem("ACCESS_TOKEN")) {
    return <Navigate to="/login" />;
  }

  const userAuth = JSON.parse(localStorage.getItem("userAuth"));

  const orderCreate = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const data = {
        first_name: nameValue,
        last_name: lastNameValue,
        email: emailValue,
        phone_number: numberValue,
        address: addressValue,
        note: noteValue,
        user: userAuth.id,
      };
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
      };

      const resp = await axios.post(baseUrl + "/tailor/order-create/", data, {
        headers,
      });
      console.log(resp);
      setIsLoading(false);
      window.location.reload();
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Helmet>
        <title>{t("basketPaymentHead")} </title>
      </Helmet>
      <Navbar />
      <h2 className="head_of_address_paymenth2">{t("addresInfo")}</h2>
      <div className="address_payment_container">
        {isLoading1 ? (
          <Loading />
        ) : myOrderIDvalue.length === 0 ? (
          <form onSubmit={orderCreate}>
            <div className="user_information">
              <div className="name_lastName">
                <input
                  type="text"
                  placeholder={t("regName")}
                  value={nameValue}
                  onChange={nameChange}
                  required
                />
                <input
                  type="text"
                  placeholder={t("regLastNa")}
                  value={lastNameValue}
                  onChange={lNameChange}
                  required
                />
              </div>
              <div className="email_phone">
                <input
                  type="email"
                  placeholder="Email..."
                  value={emailValue}
                  onChange={emailChange}
                  required
                />
                <input
                  type="tel"
                  placeholder="Tel:..."
                  value={numberValue}
                  onChange={numberChange}
                  required
                />
              </div>
              <textarea
                cols="10"
                rows="6"
                placeholder={t("deliveryAddress")}
                value={addressValue}
                onChange={adressChange}
                required
              ></textarea>
              <textarea
                cols="10"
                rows="2"
                placeholder={t("note")}
                value={noteValue}
                onChange={noteChange}
              ></textarea>
              <button
                className="save_userInfo_btn"
                style={{ width: "150px", marginTop: "10px" }}
              >
                {isLoading ? <span className="loader"></span> : t("saveBtn")}
              </button>
            </div>
          </form>
        ) : (
          // myOrderIDvalue.map((data, i) => {
          //   return <VisitCartOfUser key={i} dataMyAddress={data} />;
          // })
          <VisitCartOfUser
            dataMyAddress={myOrderIDvalue[myOrderIDvalue.length - 1]}
          />
        )}

        <PaymentDesk myOrderIDvalue={myOrderIDvalue} />
      </div>
      <Footer />
    </div>
  );
};

export default AddressPayment;
