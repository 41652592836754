import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import FavoritesBox from "../components/FavoritesBox";
import { Navigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import { useTranslation } from "react-i18next";

const Favourites = () => {
  const { t } = useTranslation();
  if (!localStorage.getItem("ACCESS_TOKEN")) {
    return <Navigate to="/login" />;
  }
  return (
    <div>
      <Helmet>
        <title>{t("favouriteHead")} </title>
      </Helmet>
      <Navbar />
      <FavoritesBox />
      <Footer />
    </div>
  );
};

export default Favourites;
