import React, { useEffect } from "react";
import ApprovedGif from "../logoimg/approved.gif";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const Approved = () => {
  let { token } = useParams();
  useEffect(() => {
    localStorage.removeItem("basketList");
  }, []);

  let { tokenX } = useSelector((state) => state.Data);

  token = tokenX;

  console.log(token);

  const navigate = useNavigate();

  const goToHome = () => {
    navigate("/");
  };
  return (
    <div className="approved_page_container">
      <h2> Ödəniş təsdiq edildi!</h2>
      <img src={ApprovedGif} alt="approved icon" />
      <button onClick={goToHome}>Əsas səhifəyə qayıt</button>
    </div>
  );
};

export default Approved;
