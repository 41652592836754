import { Link } from "react-router-dom";

import { SlLocationPin } from "react-icons/sl";
import { AiOutlinePhone } from "react-icons/ai";
import { HiOutlineMail } from "react-icons/hi";
import { AiFillInstagram } from "react-icons/ai";
import { FaFacebookF } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const Footer = () => {

  const {t}=useTranslation()
  return (
    <footer>
      <div className="aboutUs_information">
        <div className="aboutUs">
          <h4 id="haqqimizda">{t("about")}</h4>
          <p>
            {t("aboutCon")}
          </p>
        </div>

        <div className="flex_wrap">
          <div className="information">
            <h4>{t("contact")}</h4>
            {/* <div>
              <AiOutlinePhone size={22} />
              <p className="phone_number">
                <a href="tel:+994773992525">+99477-399-25-25</a>
              </p>
            </div> */}
            <div>
              <HiOutlineMail size={22} />
              <p className="email">
                <a href="mailto:info@empowerwoman">info@empowerwoman.az</a>
              </p>
            </div>
            <div>
              <SlLocationPin size={25} />
              <p className="adress">{t("adress")}</p>
            </div>
          </div>
          <div className="follow_us">
            <h4>{t("follow")}</h4>
            <div className="social_media">
              <Link
                to="https://www.instagram.com"
                target="_blank"
                style={{ color: "#000" }}
              >
                <AiFillInstagram size={22} />
              </Link>
              <Link
                to="https://www.facebook.com"
                target="_blank"
                style={{ color: "#000" }}
              >
                <FaFacebookF size={20} />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
