import axios from 'axios';
import { baseUrl } from './base_url';

// Axios instance
const api = axios.create({
  baseURL: baseUrl,
});

// Request interceptor
api.interceptors.request.use(
  async (config) => {
    const accessToken = localStorage.getItem('ACCESS_TOKEN');
    if (accessToken) {
      config.headers['Authorization'] = 'Bearer ' + accessToken;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Response interceptor
api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const refreshToken = localStorage.getItem('REFRESH_TOKEN');
      try {
        const response = await axios.post(`${baseUrl}/account/refresh/`, {
          refresh: refreshToken,
        });
        localStorage.setItem('ACCESS_TOKEN', response.data.access);
        api.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.access;
        return api(originalRequest);
      } catch (err) {
        console.log('Refresh token expired or invalid');
        localStorage.removeItem('ACCESS_TOKEN');
        localStorage.removeItem('REFRESH_TOKEN');
        window.location.href = '/login'; // or navigate to login
      }
    }
    return Promise.reject(error);
  }
);

export default api;
