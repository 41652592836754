import axios from "axios";
import {
  changeIsloading,
  getCategoryListFunc,
  getFavoriteListFunc,
  getLoggedInUserFunc,
  getMyFavoriteListFunc,
  getMyOrderIDfunc,
  getMyOrderItemsFunc,
  getProductsListFunc,
  getRetrieveProFunc,
  getTokenObjFunc,
  getUserObjFunc,
  getUsersListFunc,
} from "../redux/MainReducer";


// Base url
const baseUrl = "https://api.empowerwoman.az/api"



// For user List
export const getUsersList = () => async (dispatch) => {
  return await axios
    .get(baseUrl + "/api/account/user-list/")
    .then((resp) => {
      // console.log(resp.data);
      dispatch(getUsersListFunc(resp.data));
    })
    .catch((err) => { });
};

// For a specific user Для сохранения данных 

// export const getLoggedInuser = (userID) => async (dispatch) => {
//   return await axios
//     .get(baseUrl + 
//       `/account/user-retrieve-update-delete/${userID}/`,
//       {
//         headers: {
//           Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
//         },
//       }
//     )
//     .then((resp) => {
//       // console.log(resp);
//       dispatch(getLoggedInUserFunc(resp.data));
//     })
//     .catch((err) => {});
// };
// For  products list
export const getProductsList = () => async (dispatch) => {
  return await axios
    // https://api.empowerwoman.az/api/tailor/home-product-list/
    .get(baseUrl + "/tailor/home-product-list/")
    .then((resp) => {

      dispatch(getProductsListFunc(resp.data));
    })
    .catch((err) => { });
};

// For a single page of products
export const getRetrieveProduct = (id) => async (dispatch) => {

  if (id) {
    try {
      const resp = await axios.get(baseUrl + `/tailor/product-retrieve/${id}/`)
      dispatch(getRetrieveProFunc(resp.data));
    } catch (error) { }
  }

};

// For to add to favorite

export const getFavoriteList = () => async (dispatch) => {
  return await axios
    .get(baseUrl + "/api/tailor/favourite-list/", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
      },
    })
    .then((resp) => {
      // console.log(resp);
      dispatch(getFavoriteListFunc(resp.data));
    })
    .catch((err) => { });
};

// For to add to myfavorite list
export const getMyFavoriteList = () => async (dispatch) => {
  const accessToken = localStorage.getItem("ACCESS_TOKEN");
  if (accessToken) {
    try {
      const resp = await axios.get(
        baseUrl + "/tailor/myfavourite-list/",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
          },
        }
      );
      
      //
      dispatch(getMyFavoriteListFunc(resp.data));
    } catch (err) {
      // console.log(err.response.status);
      if(err?.response?.status===401){
        window.location="/login"
      }
      
    }
  }
};

// For myorder adress information
export const getMyOrderID = () => async (dispatch) => {
  return await axios
    .get(baseUrl + "/tailor/myorder/", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
      },
    })
    .then((resp) => {
      // console.log(resp);
      dispatch(getMyOrderIDfunc(resp.data));
    })
    .catch((err) => {
      // console.log(err);
    });
};

// For myorderitems
export const getMyOrderItems = () => async (dispatch) => {
  return await axios
    .get(baseUrl + "/tailor/myorderitems/", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
      },
    })
    .then((resp) => {
      console.log(resp.data);
      if (resp.data.length === 0) {
        dispatch(changeIsloading(false))
      }
      dispatch(getMyOrderItemsFunc(resp.data));

    })
    .catch((err) => { 
      // console.log(err);
      if(err?.response?.status===401){
        window.location="/login"
      }
    });
};

// For category list
export const getCategoryList = () => async (dispatch) => {
  return await axios
    .get(baseUrl + "/tailor/category-list/")
    .then((resp) => {

      dispatch(getCategoryListFunc(resp.data));
    })
    .catch((err) => { });
};


export const getUser = (id) => async (dispatch) => {
  return await axios
    .get(baseUrl + `/tailor/order-retrieve-update-delete/${id}/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
      }
    })
    .then((resp) => {

      dispatch(getUserObjFunc(resp.data));
    })
    .catch((err) => { });
};




export const getToken = () => async (dispatch) => {
  return await axios
    .get(`${baseUrl}/tailor/generate-token/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
      }
    })
    .then((resp) => {
      // console.log(resp.data.token);
      dispatch(getTokenObjFunc(resp.data.token));
    })
    .catch((err) => { 
      
    });
};
