import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { FiShoppingBag } from "react-icons/fi";
import logo from "../logoimg/logo.png";
import { useTranslation } from "react-i18next";

const Navbar = () => {
  const { t, i18n } = useTranslation();

  // const changeLanguage = (language) => {
  //   i18n.changeLanguage(language, () => {
  //     // Колбэк вызывается после успешного изменения языка
  //     window.location.reload();
  //   });
  // };

  const changeLanguage = (language) => {
    // console.log(language);
    i18n.changeLanguage(language, (err, t) => {
      if (err) return console.log('something went wrong loading', err);
      // Обновите UI компоненты, если необходимо
    });
  };
  
  const navigate = useNavigate();
  const { hash, key } = useLocation();

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (hash) {
      const targetElement = document.getElementById(hash.substring(1));
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [key, hash]);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    });
  }, []);

  const goTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const goToLogin = () => {
    window.location.href = "/login";
  };
  const goToRegister = () => {
    window.location.href = "/register";
  };

  const ACCESS_TOKEN = localStorage.getItem("ACCESS_TOKEN");
  const userAuth = JSON.parse(localStorage.getItem("userAuth"));

  const goToMyFavourite = () => {
    navigate("/myfavourites");
  };
  const goToBasket = () => {
    navigate("/basket");
  };
  const goToMyOrderItems = () => {
    navigate("/myorders");
  };

  const logExit = () => {
    localStorage.removeItem("ACCESS_TOKEN");
    localStorage.removeItem("userAuth");
    window.location.href = "/";
  };

  const basket = useSelector((state) => state.Data.basket);
  let total = 0;
  basket.map((data) => {
    return (total += data.quantity);
  });

  const [isOpen, setIsOpen] = useState(false);

  const setOpenedState = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header>
      <div className="header_container">
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="empowerwoman logo" />
          </Link>
        </div>
        <nav>
          <ul className={!isOpen ? "nav_list" : "nav_list open_nav_list"}>
            <li onClick={goTop}>
              <Link to="/">{t("navbar1")}</Link>
            </li>
            <li>
              <Link to="/#mehsullarimiz">{t("navbar2")}</Link>
            </li>
            <li>
              <Link to="/#haqqimizda">{t("navbar3")}</Link>
            </li>
            <li>
              <Link to="/contact">{t("navbar4")}</Link>
            </li>
          </ul>
        </nav>

        <button
          className="btn-scrollTop"
          style={{ display: isVisible ? "block" : "none" }}
          onClick={goTop}
        >
          <i className="fa-solid fa-arrow-up"></i>
        </button>

        <div className="header_icons_container">
          <div className="user_container_modals">
            <i
              className={
                ACCESS_TOKEN
                  ? "fa-regular fa-circle-user"
                  : "fa-regular fa-user"
              }
            ></i>

            {ACCESS_TOKEN ? (
              <div className="user_modal_hover">
                <div
                  style={{ fontSize: "1.3rem" }}
                  className="loggedInUser_content_name"
                >
                  <span>{userAuth.email}</span>
                </div>
                <div className="user_modal_hover_i">
                  <div
                    onClick={goToMyFavourite}
                    className="loggedInUser_content"
                  >
                    <i className="fa-solid fa-heart"></i>
                    <span>{t("favory")}</span>
                  </div>
                  <div
                    className="loggedInUser_content"
                    onClick={goToMyOrderItems}
                  >
                    <i className="fa-solid fa-box"></i>
                    <span>{t("orders")}</span>
                  </div>
                  <div onClick={logExit} className="loggedInUser_content">
                    <i className="fa-solid fa-right-from-bracket"></i>
                    <span>{t("exit")}</span>
                  </div>
                </div>
              </div>
            ) : (
              <div className="user_modal_hover">
                <button onClick={goToLogin}>{t("login")}</button>
                <button onClick={goToRegister}>{t("registr")}</button>
              </div>
            )}
          </div>

          <div className="bag_icon" onClick={goToBasket}>
            <FiShoppingBag size={22} style={{ marginTop: "3px" }} />
            <span className="basket_count">{total}</span>
          </div>
          <div className="social_media_icons">
            <Link
              to="https://www.instagram.com"
              target="_blank"
              style={{ color: "#000" }}
            >
              {/* <AiFillInstagram className="instagram" size={25} /> */}
              <i className="fa-brands fa-instagram"></i>
            </Link>
            <Link
              to="https://www.facebook.com"
              target="_blank"
              style={{ color: "#000" }}
            >
              {/* <FaFacebookF className="facebook" size={20} /> */}
              <i className="fa-brands fa-square-facebook"></i>
            </Link>
          </div>
          <div className="lang_menu">
            <button onClick={() => changeLanguage("az")}>AZ</button>
            <span>|</span>
            <button onClick={() => changeLanguage("en-us")}>EN</button>
          </div>
          <div>
            <div className="burger_menu" onClick={setOpenedState}>
              <span
                className={!isOpen ? "burger_class" : "burger_class clicked"}
              ></span>
              <span
                className={!isOpen ? "burger_class" : "burger_class clicked"}
              ></span>
              <span
                className={!isOpen ? "burger_class" : "burger_class clicked"}
              ></span>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
