import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { baseUrl } from "../../base_url";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { closePopUp } from "../../redux/MainReducer";

const PopUpForChangePass = () => {
  const dispatch = useDispatch();
  const navigate=useNavigate()
  const { popUpValue } = useSelector((state) => state.Data);

  const [email, setEmail] = useState("");

  const emailChange = (e) => {
    setEmail(e.target.value);
  };

  const changePass = async (e) => {
    e.preventDefault();

    const data = {
      email: email,
    };

    try {
      const resp = await axios.post(
        `${baseUrl}/account/send_email_reset_password/`,
        data
      );

      if (resp.status === 200) {
        Swal.fire({
          icon: "success",
          title: "E-poçt Göndərildi",
          text: "Parola sıfırlama linki email adresinize gönderildi.",
          showConfirmButton: true,
          confirmButtonText: "OK",
        }).then(() => {
          // Swal kapandıktan sonra modalı açmak için Redux state güncellemesi
          // dispatch(forgorPswInfoChange());
          // navigate("/forget-password")
          window.location.reload()
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="pup_up_for_change_pass">
      <div className="pup_up_for_change_pass_content">
        <p onClick={() => dispatch(closePopUp())} className="close_btn">
          Bağla [X]
        </p>
        <hr />
        <form action="" className="p_inp_pop" onSubmit={changePass}>
          <label htmlFor="email" className="p_inp_pop_flex">
            <p>Qeydiyyatdan keçdiyiniz poçtunuzu daxil edin.</p>
            <input
              type="email"
              id="email"
              placeholder="Email"
              value={email}
              onChange={emailChange}
              required
            />
          </label>
          <button type="submit">Göndər</button>
        </form>
      </div>
      {/* forgorPswInfoChangeModal true ise modalı göster */}
      {/* {forgorPswInfoChangeModal && <ForgorPswInfoChangeModal />} */}
    </div>
  );
};

export default PopUpForChangePass;
