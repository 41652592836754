import React from "react";
import { BsTrash } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { decr, delitem, incr } from "../redux/MainReducer";
import i18n from "../18n";
import { useTranslation } from "react-i18next";

const BasketBoxSecond = ({ data }) => {
  const currLang = i18n.language;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  return (
    <div className="left_side">
      <div className="b_img">
        <img src={data.product_images[0]?.image} alt="data.title" />
      </div>
      <div className="left_side_content">
        <div className="item_info">
          <p className="category">
            {data?.translations?.az?.title &&
              data?.translations[currLang]?.title}
          </p>
          <div className="color_size">
            <p className="color_of_circle">
              {t("color")}:
              <div
                style={{ background: `${data.color_code}` }}
                className="circle_of_choose"
              ></div>
            </p>
            <p>
              {t("size")}: <span>{data.choise_size}</span>
            </p>
          </div>
        </div>
        <div className="b_buttons">
          <button onClick={() => dispatch(decr(data))}>-</button>
          <span>{data.quantity}</span>
          <button onClick={() => dispatch(incr(data))}>+</button>
        </div>
        <div className="b_price">
          <span>
            {data.sale_price
              ? (((data.sale_price*1.015)) * data.quantity).toFixed(2)
              : (((data.price*1.015)) * data.quantity).toFixed(2)}{" "}
            AZN
          </span>
        </div>
        <div className="trash">
          <BsTrash onClick={() => dispatch(delitem(data))} />
        </div>
      </div>
    </div>
  );
};

export default BasketBoxSecond;
