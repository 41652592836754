import { useTranslation } from "react-i18next";

export const initialState = {
  usersList: [],
  loggedInUser: {},
  getProductsListValue: [],
  getRetriveProductValue: {},
  basket: JSON.parse(localStorage.getItem("basketList")) || [],
  basketCount: 0,
  getFavoriteListValue: [],
  getMyFavoriteListValue: [],
  colorValue: {},
  sizeValue: {},
  myOrderIDvalue: [],
  myOrderItemsValue: [],
  categoryListValue: [],
  myOrderItemsAddress: [],
  isLoading: true,
  popUpValue:false,
  forgorPswInfoChangeModal: false,



  questionAnswer:[
    {
      id:1,
      h4:"Sizdə çatdırılma xidməti var və hansı ərazilər üzrə həyata keçirilir?",
      span:"Bəli, çatdırılma mövcuddur və Bakı ərazisində pulsuzdur. Rayonlara poçt vasitəsi ilə göndərilir."
    },
    {
      id:2,
      h4:"Ödəniş şərtləri sizdə necədir ?",
      span:"Sayt üzərindən ödəniş kart vasitəsi ilə həyata keçirilir. Satış ünvanlarında həm nəğd və ya kart vasitəsi ilə mümkündür."
    },
    {
      id:3,
      h4:"Qaytarılma şərtləri",
      span:"Alınan məhsul qeyri qaytarılmır və dəyişdirilmir."
    },
  ],

  taksitModal: false,
  userObj: {},
  tokenX: ""
};
