import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMyFavoriteList } from "../action/MainAction";
import axios from "axios";
import Swal from "sweetalert2";
import i18n from "../18n";
import { useTranslation } from "react-i18next";
import { baseUrl } from "../base_url";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const ProductsGridBox = ({ dataProducts }) => {
  
  const currLang = i18n.language;
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const getMyFavoriteListValue = useSelector(
    (state) => state.Data.getMyFavoriteListValue
  );

  useEffect(() => {
    dispatch(getMyFavoriteList());
  }, [dispatch]);

  const checkItem = getMyFavoriteListValue.find(
    (data) => data?.product?.id === dataProducts.id
  );

 

  const loggedInUser = useSelector((state) => state.Data.loggedInUser);
  const ACCESS_TOKEN=localStorage.getItem("ACCESS_TOKEN")
  const userAuth = JSON.parse(localStorage.getItem("userAuth"));



  // Добавление или удаление к списку избранных
  const addRemoveFavorite = async (id) => {
    if (!ACCESS_TOKEN) {
      window.location.href = "/login";
    } else {
      try {
        if (!checkItem) {
          const data = {
            user: userAuth.id, 
            product: id,
          };
          const headers = {
            Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
          };
  
          const resp = await axios.post(baseUrl + "/tailor/favourite-create/", data, { headers });
  
          if (resp.status === 201) {
            dispatch(getMyFavoriteList());
  
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: t("succFav"),
              showConfirmButton: false,
              timer: 1000,
            });
          }
        } else {
          const resp = await axios.delete(baseUrl + `/tailor/favourite-retrieve-update-delete/${checkItem.id}/`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
            },
          });
  
          if (resp.status === 204) {
            dispatch(getMyFavoriteList());
          }
        }
      } catch (err) {
        console.error(err); // Логирование ошибки для отладки
        
        window.location.href = "/login";
      }
    }
  };
  
  // Открытие страницы единичного продукта при нажатии
  const openNewWindow = (e) => {
    // console.log(dataProducts.id);
    if (
      !e.target.classList.contains("heart_for_favorite") &&
      !e.target.classList.contains("fa-heart")
    ) {
      window.open(`/product/${dataProducts.id}`, "_blank");
    }
  };

  return (
    <div className="grid_box" onClick={openNewWindow}>
      <div
        className="heart_for_favorite"
        onClick={() => addRemoveFavorite(dataProducts.id)}
      >
        <i
          className={checkItem ? "fa-solid fa-heart" : "fa-regular fa-heart"}
        ></i>
      </div>
      <div className="product_images">
        <img src={dataProducts?.product_images?.image} alt="" />

        {/* LazyLoading Работает медленно, что замедлает работу сайта */}

        {/* <LazyLoadImage
          effect="blur"
          src={dataProducts?.product_images?.image}
          alt={
            dataProducts?.translations?.az?.title &&
            dataProducts?.translations[currLang]?.title
          }

          // Это для отображения в заднем фоно лого коспании при загрузке
          // placeholderSrc={process.env.PUBLIC_URL+ "/logo192x192.png"}
        /> */}
      </div>
      <div className="grid_box_content">
        <p className="title">
          {dataProducts?.translations?.az?.title &&
            dataProducts?.translations[currLang]?.title}
        </p>
            {/* Цена  */}
        {dataProducts.sale_price ? (
          <div>
            <del className="price_del">
              <span>{(dataProducts.price*1.015).toFixed(2)} AZN</span>
            </del>
            <span className="price">{(dataProducts.sale_price*1.015).toFixed(2)} AZN</span>
          </div>
        ) : (
          <span className="price">{(dataProducts.price*1.015).toFixed(2)} AZN</span>
        )}
      </div>
    </div>
  );
};

export default ProductsGridBox;
