import React from "react";
import DeclinedGif from "../logoimg/declined.gif";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const Declined = () => {
  const navigate = useNavigate();
  let { token } = useParams();

  let { tokenX } = useSelector((state) => state.Data);

  token = tokenX;

  const goToHome = () => {
    navigate("/");
  };
  return (
    <div className="approved_page_container">
      <h2>Ödəniş rədd edildi!</h2>
      <img src={DeclinedGif} alt="declined icon" />
      <button onClick={goToHome}>Əsas səhifəyə qayıt</button>
    </div>
  );
};

export default Declined;
