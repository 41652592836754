import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMyFavoriteList, getProductsList } from "../action/MainAction";
import MyFavouriteListBox from "./MyFavouriteListBox";
import { useTranslation } from "react-i18next";
import Loading from "./modals/Loading";

const FavoritesBox = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProductsList());
    dispatch(getMyFavoriteList());
  }, [dispatch]);

  const getProductsListValue = useSelector(
    (state) => state.Data.getProductsListValue
  );
  // Переменная для loader
  const isLoading = useSelector((state) => state.Data.isLoading);

  const getMyFavoriteListValue = useSelector(
    (state) => state.Data.getMyFavoriteListValue
  );


  const myFavouriteList = getProductsListValue.filter((product) =>
    getMyFavoriteListValue.some((data) => data.product === product.id)
  );
  

  return (
    <div>
      <h2 className="favory_font_h2">{t("favory")}</h2>
      <div className="grid_box_favorite">
        {isLoading ? (
          <Loading />
        ) : (
          getMyFavoriteListValue.map((data, i) => (
            <MyFavouriteListBox key={i} dataFavList={data} />
          )) ||
          (getMyFavoriteListValue.length === 0 && (
            <p className="when_notmy_favourites">{t("favStatus")}.</p>
          ))
        )}
      </div>
    </div>
  );
};

export default FavoritesBox;
