import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { baseUrl } from "../base_url";
import Swal from "sweetalert2";
// import baseUrl from '../base_url'

const LoginBox = ({uid,token}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [psw, setPsw] = useState("");
  const [pswCon, setPswCon] = useState("");
  const [spanErr, setSpanErr] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConPassword, setShowConPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };


  const toggleShowConPassword = () => {
    setShowConPassword(!showConPassword);
  };

  const emailChange = (e) => {
    setEmail(e.target.value);
  };

  const pswChange = (e) => {
    setPsw(e.target.value);
  };
  const pswConChange = (e) => {
    setPswCon(e.target.value);
  };

 

  const passChange = async (e) => {
    e.preventDefault();
    setSpanErr("");

    const data = {
      email: email,
      new_password: psw,
      new_password_confirm: pswCon,
    };

    

    if (psw !== pswCon) {
      alert("Parollar eyni deyil");
    } else {
      try {
        const resp = await axios.post(
          `${baseUrl}/account/reset_password/${uid}/${token}/`,
          data
        );
        

        if (resp.status === 200) {
          Swal.fire({
            icon: 'success',
            // title: 'Məlumatlar yeniləndi',
            text: 'Məlumatlar yeniləndi',
            showConfirmButton: true,
            confirmButtonText: 'Tamam'
          })
          // const emailData = {
          //   email: email,
          // };

          // try {
          //   const emailResp = await axios.post(
          //     baseUrl + "/account/send_email_reset_password/",
          //     emailData
          //   );
          //   console.log(emailResp);
          // } catch (err) {
          //   console.log(err);
          // }
          
        }
      } catch (err) {
        
        if (err?.response?.data?.error?.[0]) {
          alert("Parolda ən azı 1 böyük hərf olmalıdır");
        }
      }
    }
  };

  return (
    <div className="login_register_container">
      {isLoading ? (
        <div className="lds-dual-ring"></div>
      ) : (
        <div className="login_box">
          <h1 className="login_header">Yeni Parol</h1>
          <form onSubmit={passChange}>
            <div>
              <label htmlFor="email">
                <input
                  id="email"
                  value={email}
                  onChange={emailChange}
                  type="email"
                  placeholder={t("email")}
                  required
                />
              </label>
            </div>
            <div>
              {/* Password */}
              <label htmlFor="password" className="eye_psw">
                <input
                  value={psw}
                  onChange={pswChange}
                  type={showPassword ? "text" : "password"}
                  placeholder={t("passw")}
                  required
                />
                <i
                  className={`fa-regular fa-eye${showPassword ? "-slash" : ""}`}
                  onClick={toggleShowPassword}
                ></i>
              </label>
              {/* Confirm password */}
              <label htmlFor="password" className="eye_psw">
                <input
                  value={pswCon}
                  onChange={pswConChange}
                  type={showConPassword ? "text" : "password"}
                  placeholder="Şifrəni təstiqlə"
                  required
                />
                <i
                  className={`fa-regular fa-eye${
                    showConPassword ? "-slash" : ""
                  }`}
                  onClick={toggleShowConPassword}
                ></i>
              </label>
              <span className="error">{spanErr}</span>
            </div>
            <button style={{ cursor: "pointer" }}>Dəyişdir</button>
          </form>
        </div>
      )}
    </div>
  );
};

export default LoginBox;
