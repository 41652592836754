import React, { useRef } from "react";
import { FiChevronUp } from "react-icons/fi";

const Accordionİtems = ({ faqItem, onClick, isOpen }) => {
  const itemRef = useRef();
 
  return (
    <li className="accordion_item">
      <button className="accordion_head" onClick={() => onClick()}>
        {faqItem.h4}
        <FiChevronUp className={isOpen ? "active" : ""} />
      </button>
      <div
        className="accordion_collapse"
        style={
          isOpen ? { height: itemRef.current.scrollHeight } : { height: "0px" }
        }
      >
        <div className="accordion_body" ref={itemRef}>
          {faqItem.span}
        </div>
      </div>
    </li>
  );
};

export default Accordionİtems;
