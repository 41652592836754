import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import Products from "../components/Products";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

const Home = () => {
  const { t } = useTranslation();
  
  return (
    <div>
      <Helmet>
        <title>{t("homeHead")}</title>
        <meta
          name="keywords"
          content="empowerwoman.az, qadın geyimləri, Bakı, Azərbaycan, sifariş ilə, online ödəmə, tşört, elbise, sarafan, tulum, geyim dəsti, bluz, köynək, şalvar, topik modaya uyğun, ayaqqabı, çanta, aksessuarlar"
        />
        <meta
          name="description"
          content="Empower Woman - Bakı, Azərbaycan'da qadın geyimləri və aksessuarları üzrə onlayn mağaza. Sifariş ilə və online ödəmə ilə rahatlıqla almaq üçün tşört, elbise, sarafan, tulum, geyim dəsti, bluz, köynək, şalvar, topik və daha bir çoxunu əldə edin."
        />
        
      </Helmet>
      <Navbar />
      <Hero />
      <Products />
      <Footer />
    </div>
  );
};

export default Home;
