import { useEffect, useState } from "react";
import { getCategoryList, getProductsList } from "../action/MainAction";
import { useDispatch, useSelector } from "react-redux";
import ProductsGridBox from "./ProductsGridBox";
import CategoryModal from "./modals/CategoryModal";
import SearchResult from "./SearchResult";
import { BiFilterAlt } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import Loading from "./modals/Loading";

const Products = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getProductsList());
    dispatch(getCategoryList());
  }, [dispatch]);

  const getProductsListValue = useSelector(
    (state) => state.Data.getProductsListValue
  );

  const categoryListValue = useSelector(
    (state) => state.Data.categoryListValue
  );

  const [isOpen, setIsOpen] = useState(false);

  const modalOpenClose = (e) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };
  const [selectedCategories, setSelectedCategories] = useState([]);

  const filteredProducts =
    selectedCategories.length === 0
      ? getProductsListValue
      : getProductsListValue.filter((data) =>
          data.categories.some((cat) => selectedCategories.includes(cat.id))
        );

  const [inp, setInp] = useState("");

  const resultsTitle = getProductsListValue.filter((data) => {
    return (
      inp &&
      data &&
      data.translations.az.title &&
      data.translations.az.title.toLowerCase().includes(inp.toLowerCase())
    );
  });

  // Временно закрыл, но надо открыть 13-05-2024
  const resultCategory = getProductsListValue.filter((data) => {
    return (
      data &&
      data.categories.some((category) =>
        category.translations.az.name.toLowerCase().includes(inp.toLowerCase())
      )
    );
  });

  const searchInp = (value) => {
    setInp(value);
  };

  return (
    <main>
      <h2 className="main_header" id="mehsullarimiz">
        {t("headPro")}
      </h2>

      <div className="main_container">
        <div className="category_checkbox">
          <CategoryModal
            dataCategory={categoryListValue}
            selectedCategories={selectedCategories}
            setSelectedCategories={setSelectedCategories}
          />
        </div>

        <div style={{ paddingBottom: "100px" }}>
          <div className="search_and_catecory">
            <div className="seach_and_cat_cont">
              <div className="search_inp_products">
                <input
                  type="search"
                  placeholder={t("search")}
                  value={inp}
                  onChange={(e) => searchInp(e.target.value)}
                />
                <i className="fa-solid fa-magnifying-glass"></i>
              </div>
              {inp && <SearchResult resultsTitle={resultsTitle} />}
            </div>
            <div onClick={modalOpenClose} className="category_choise_modal">
              <p style={{ userSelect: "none" }}>{t("categ")}</p>
              <BiFilterAlt />
              {isOpen && (
                <CategoryModal
                  dataCategory={categoryListValue}
                  selectedCategories={selectedCategories}
                  setSelectedCategories={setSelectedCategories}
                />
              )}
            </div>
          </div>

          <div className="grid_container">
            
            {!isOpen ? (
              getProductsListValue.length !== 0 ? (
                filteredProducts.map((data, i) => (
                  <ProductsGridBox key={i} dataProducts={data} />
                ))
              ) : (
                <Loading />
              )
            ) : filteredProducts.length !== 0 ? (
              filteredProducts.map((data, i) => (
                <ProductsGridBox key={i} dataProducts={data} />
              ))
            ) : (
              <h3 className="when_notmy_favourites">{t("catErr")} :(</h3>
            )}
          </div>
        </div>
      </div>
    </main>
  );
};

export default Products;
