import React from "react";
import Footer from "../components/Footer";
import LoginBox from "../components/LoginBox";
import Navbar from "../components/Navbar";
import { Helmet } from "react-helmet";

import { useTranslation } from "react-i18next";

const Login = () => {
  const {t}=useTranslation()
  return (
    <div>
      <Helmet>
        <title>{t("loginHead")}</title>
      </Helmet>
      <Navbar />
      <LoginBox />
      <Footer />
    </div>
  );
};

export default Login;
