
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import ProductCart from "../components/ProductCart";
import { Helmet } from "react-helmet";
const Product = () => {
  
 
  return (
    <div>
      <Helmet>
        <title>Empower Woman - Products</title>
      </Helmet>
      <Navbar />
      <ProductCart />
      <Footer />
    </div>
  );
};

export default Product;
