import React from 'react'

const Loading = () => {
  return (
    <div className="container">
    <span className="loader"></span>
    
  </div>
  )
}

export default Loading