import { Link } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import InputMask from "react-input-mask";
import { useTranslation } from "react-i18next";

const RegisterBox = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showRePassword, setShowRePassword] = useState(false);

  const [emailErr, setEmailErr] = useState("");
  const [spamPswErr, setPswErr] = useState("");

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const toggleShowRePassword = () => {
    setShowRePassword(!showRePassword);
  };

  const [firstName, setRegName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [tel, setTel] = useState("");
  const [psw, setPsw] = useState("");
  const [rPsw, setRpsw] = useState("");

  const nameChange = (e) => {
    setRegName(e.target.value);
  };

  const lastNaChange = (e) => {
    setLastName(e.target.value);
  };

  const emailChange = (e) => {
    setEmail(e.target.value);
  };
  const telChange = (e) => {
    setTel(e.target.value);
  };

  const pswChange = (e) => {
    setPsw(e.target.value);
  };

  const rPswChange = (e) => {
    setRpsw(e.target.value);
  };

  // Регистрация при нажатии
  const regSubmit = async (e) => {
    e.preventDefault();
    setEmailErr("");
    setPswErr("");
    setIsLoading(true);
    const data = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone_number: tel,
      password: psw,
    };

    if (psw !== rPsw) {
      setPswErr(t("regErr2"));
      setIsLoading(false);
    } else {
      await axios
        .post("https://api.empowerwoman.az/api/account/user-create/", data)
        .then((resp) => {
          console.log(resp);
          if (resp.status === 201) {
            setIsLoading(false);
            Swal.fire({
              title: t("regSucc"),
              icon: "success",
              html: ` `,
              showCloseButton: true,
            });
            setRegName("")
            setLastName("")
            setEmail("")
            setTel("")
            setPsw("")
            setRpsw("")

          }
        })
        .catch((err) => {
          setIsLoading(false);

          if (err?.response?.status === 400) {
            setEmailErr(t("regErr1"));
          }
        });
    }
  };

  return (
    <div className="login_register_container">
      <div className="register_box">
        <h1 className="register_header">{t("goRegistr")}</h1>

        <form onSubmit={regSubmit}>
          <div>
            <label htmlFor="name">
              <input
                id="name"
                value={firstName}
                onChange={nameChange}
                type="text"
                placeholder={t("regName")}
                required
              />
            </label>
          </div>
          <div>
            <label htmlFor="last_name">
              <input
                id="last_name"
                value={lastName}
                onChange={lastNaChange}
                type="text"
                placeholder={t("regLastNa")}
                required
              />
            </label>
          </div>
          <div>
            <label htmlFor="email">
              <input
                id="email"
                value={email}
                onChange={emailChange}
                type="email"
                placeholder={t("email")}
                required
              />
            </label>

            <span className="error">{emailErr}</span>
          </div>
          <div>
            <label htmlFor="phone_number">
              <InputMask
                id="phone_number"
                value={tel}
                onChange={telChange}
                mask="+\9\94 (99) 999-99-99"
                placeholder="+994 (00) 000-00-00"
                required
              ></InputMask>
            </label>
          </div>
          <div className="eye_psw">
            <input
              value={psw}
              onChange={pswChange}
              type={showPassword ? "text" : "password"}
              placeholder={t("passw")}
              required
            />
            <i
              className={`fa-regular fa-eye${showPassword ? "-slash" : ""}`}
              onClick={toggleShowPassword}
            ></i>
          </div>
          <div className="eye_psw">
            <input
              value={rPsw}
              onChange={rPswChange}
              type={showRePassword ? "text" : "password"}
              placeholder={t("passRe")}
              required
            />
            <i
              className={`fa-regular fa-eye${showRePassword ? "-slash" : ""}`}
              onClick={toggleShowRePassword}
            ></i>
          </div>
          <span className="error">{spamPswErr}</span>

          <button
            type="submit"
            style={{ cursor: "pointer" }}
            disabled={isLoading}
          >
            {isLoading ? <span className="loader_reg"></span> : t("continue")}
          </button>
          <span className="go_to_login">
            {t("ifHaveAcc")}
            <Link to="/login" className="link_text">
              {t("login")}
            </Link>
          </span>
        </form>
      </div>
    </div>
  );
};

export default RegisterBox;
