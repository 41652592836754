import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { colorSelection } from "../redux/MainReducer";
import i18n from "../18n";

const ColorsOfProduct = ({ dataColor }) => {
  const currLang = i18n.language;
  const dispatch = useDispatch();
  const colorValue = useSelector((state) => state.Data.colorValue);
 
  return (
    <li
      style={{
        background: `${dataColor?.color_code}`,
        padding: "5px",
        border:
          colorValue?.color_code === dataColor?.color_code
            ? "2px solid black"
            : "",
      }}
      title={
        dataColor?.translations?.az?.color &&
        dataColor?.translations[currLang]?.color
      }
      onClick={() => dispatch(colorSelection(dataColor))}
    ></li>
  );
};

export default ColorsOfProduct;
