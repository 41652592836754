import React, { useState } from "react";
import UserInfoModal from "./UserInfoModal";
import { useTranslation } from "react-i18next";

const VisitCartOfUser = ({ dataMyAddress }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <div className="visit_cart_user ">
      <div className="h3_span_inuser">
        <h3>{t("nameAndLastN")}: </h3>
        <span>
          {dataMyAddress?.first_name} {dataMyAddress?.last_name}
        </span>
      </div>
      <div className="h3_span_inuser">
        <h3>Email: </h3>
        <span>{dataMyAddress?.email}</span>
      </div>
      <div className="h3_span_inuser">
        <h3>Tel: </h3>
        <span>{dataMyAddress?.phone_number}</span>
      </div>
      <div className="h3_span_inuser">
        <h3>{t("address")} </h3>
        <span>{dataMyAddress?.address}</span>
      </div>

      <button
        onClick={() => setIsOpen(true)}
        style={{ width: "150px" }}
        className="change_user_info_btn"
      >
        {t("changeAddress")}
      </button>
      <UserInfoModal
        setIsOPen={setIsOpen}
        isOpen={isOpen}
        dataMyAddress={dataMyAddress}
      />
    </div>
  );
};

export default VisitCartOfUser;
