import React, { useEffect } from "react";
import { getMyFavoriteList } from "../action/MainAction";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { baseUrl } from "../base_url";
import i18n from "../18n";
import { LazyLoadImage } from "react-lazy-load-image-component";

const MyFavouriteListBox = ({ dataFavList }) => {
  const dispatch = useDispatch();

  const currLang = i18n.language;

  const getMyFavoriteListValue = useSelector(
    (state) => state.Data.getMyFavoriteListValue
  );

  useEffect(() => {
    dispatch(getMyFavoriteList());
  }, [dispatch]);

  const openNewWindow = (e) => {
    if (
      !e.target.classList.contains("del_for_myfavorite") &&
      !e.target.classList.contains("fa-xmark")
    ) {
      window.open(`/product/${Number(dataFavList.product.id)}`, "_blank");
    }
  };

  const removeFavorite = async (id) => {
    const checkItem = getMyFavoriteListValue.find(
      (data) => data.product.id === id
    );

    const headers = {
      Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
    };
    // api для удаления списка избранные
    await axios({
      method: "DELETE",
      url:
        baseUrl + `/tailor/favourite-retrieve-update-delete/${checkItem.id}/`,
      headers: headers,
    })
      .then((resp) => {
        // console.log(resp);
        if (resp.status === 204) {
          dispatch(getMyFavoriteList());
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  return (
    <div className="grid_box_favorite_cart" onClick={openNewWindow}>
      <div
        className="del_for_myfavorite"
        onClick={() => removeFavorite(dataFavList?.product?.id)}
      >
        <i className="fa-solid fa-xmark"></i>
      </div>
      <LazyLoadImage
        effect="blur"
        src={dataFavList?.product?.product_images?.image}
        alt={
          dataFavList?.product.translations?.az?.title &&
          dataFavList?.product?.translations[currLang]?.title
        }
      />

      <div className="grid_box_content">
        <p className="title">
          {dataFavList?.product?.translations?.az?.title &&
            dataFavList?.product?.translations[currLang]?.title}
        </p>

        {dataFavList?.product?.sale_price ? (
          <div>
            <del className="price_del">
              <span>
                {(dataFavList?.product?.price * 1.015).toFixed(2)} AZN
              </span>
            </del>
            <span className="price">
              {(dataFavList?.product?.sale_price * 1.015).toFixed(2)} AZN
            </span>
          </div>
        ) : (
          <span className="price">
            {(dataFavList?.product?.price * 1.015).toFixed(2)} AZN
          </span>
        )}
      </div>
    </div>
  );
};

export default MyFavouriteListBox;
