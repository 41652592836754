import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import axios from "axios";
import { baseUrl } from "../base_url";
import { taksitModalFunc } from "../redux/MainReducer";
import TaksitModalSelect from "./modals/TaksitModalSelect";
import { useTranslation } from "react-i18next";

const PaymentDesk = ({ myOrderIDvalue }) => {
  const { basket, taksitModal } = useSelector((state) => state.Data);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  // Total calculation
  let total = 0;
  basket.forEach((data) => {
    total +=
      data.sale_price * 1.015
        ? data.quantity * (data.sale_price * 1.015).toFixed(2)
        : data.quantity * (data.price * 1.015).toFixed(2);
  });

  // Mapping basket items to order items
  const orderItems = basket.map((data) => ({
    product: data.id,
    quantity: data.quantity,
    color: data.choise_color,
    size: data.choise_size,
  }));

  // Payment function
  const payment = () => {
    const orderData = {
      order: {
        user: myOrderIDvalue[myOrderIDvalue.length - 1]?.user.id,
        first_name: myOrderIDvalue[myOrderIDvalue.length - 1]?.first_name,
        last_name: myOrderIDvalue[myOrderIDvalue.length - 1]?.last_name,
        email: myOrderIDvalue[myOrderIDvalue.length - 1]?.email,
        phone_number: myOrderIDvalue[myOrderIDvalue.length - 1]?.phone_number,
        address: myOrderIDvalue[myOrderIDvalue.length - 1]?.address,
        note: myOrderIDvalue[myOrderIDvalue.length - 1]?.note,
      },
      items: orderItems,
    };

    // Validation checks
    if (basket.length === 0) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Səbət boşdur",
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }

    if (myOrderIDvalue.length === 0) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: t("fillAddress"),
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }

    // Show loading state
    setIsLoading(true);

    // Send the order request
    axios
      .post(`${baseUrl}/tailor/orderitem-create/`, orderData)
      .then((resp) => {
        console.log("Order response:", resp);
        if (resp.status === 201) {
          const orderUrl = resp.data.order_url; // Payment URL
          // Redirect to payment URL without page refresh
          window.location.href = orderUrl; // Redirect to payment URL in the same tab
        }
      })
      .catch((error) => {
        console.error("Order creation failed", error);
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: t("orderCreate"),
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="right_side">
      <div className="right_side_content">
        <p>
          {t("total")}:<span>{total} AZN</span>
        </p>
        <p>
          {t("goods")}:
          <span>
            {basket.length} {t("pieces")}
          </span>
        </p>
        <div className="payment_btns">
          <button onClick={payment} disabled={isLoading}>
            {isLoading ? t("loading") : t("payment")}
          </button>
          <button onClick={() => dispatch(taksitModalFunc())}>
            {t("oneCart")}
          </button>
        </div>
      </div>

      {taksitModal && <TaksitModalSelect />}
    </div>
  );
};

export default PaymentDesk;
