import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import "animate.css";
import { useTranslation } from "react-i18next";

const CountDesk = () => {
  const { t } = useTranslation();
  const basket = useSelector((state) => state.Data.basket);
  console.log(basket);
  let total = 0;
  basket.forEach((data) => {
    const price = data.sale_price
      ? (data.sale_price * 1.015)
      : (data.price * 1.015);
    total += parseFloat((data.quantity * price).toFixed(2));
  });

  const navigate = useNavigate();
  // const loggedInUser = useSelector((state) => state.Data.loggedInUser);

  const ACCESS_TOKEN = localStorage.getItem("ACCESS_TOKEN");

  const paymentProcces = () => {
    // Swal.fire("Yaxında ödəniş sistemi qoşulacaq");
    // Swal.fire({
    //   title:
    //     "Yaxında ödəniş sistemi qoşulacaq. Hələlik isə, BİZİMLƏ ƏLƏQƏ bölümünə daxil olub bizə suallarınızı yaza bilərsiniz. Öncədən təşəkkürlər",
    //   showClass: {
    //     popup: "animate__animated animate__fadeInDown",
    //   },
    //   hideClass: {
    //     popup: "animate__animated animate__fadeOutUp",
    //   },
    // });

    if (!ACCESS_TOKEN) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: t("ifNoRegistr"),
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      navigate("/basket/payment");
    }
  };

  return (
    <div className="right_side">
      <div className="right_side_content">
        <p>
          {t("total")}:<span>{total} AZN</span>
        </p>
        <p>
          {t("proType")}:
          <span>
            {basket.length} {t("type")}
          </span>
        </p>

        <button onClick={paymentProcces}>{t("comOrd")}</button>
      </div>
    </div>
  );
};

export default CountDesk;
