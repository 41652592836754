import axios from "axios";
import React, { useState } from "react";
import { baseUrl } from "../../base_url";
import { useDispatch, useSelector } from "react-redux";
import { IoClose } from "react-icons/io5";
import { closeTaksitModalFunc } from "../../redux/MainReducer";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

const TaksitModalSelect = () => {
  const [installments, setInstallments] = useState("2");
  const [result, setResult] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { myOrderIDvalue, basket } = useSelector((state) => state.Data);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const orderItems = basket.map((data) => ({
    product: data.id,
    // order: myOrderIDvalue[0]?.order_id || 15, // Dynamic Order ID
    quantity: data.quantity,
    color: data.choise_color,
    size: data.choise_size,
  }));

  const orderData = {
    order: {
      user: myOrderIDvalue[myOrderIDvalue.length - 1]?.user.id,
      first_name: myOrderIDvalue[myOrderIDvalue.length - 1]?.first_name,
      last_name: myOrderIDvalue[myOrderIDvalue.length - 1]?.last_name,
      email: myOrderIDvalue[myOrderIDvalue.length - 1]?.email,
      phone_number: myOrderIDvalue[myOrderIDvalue.length - 1]?.phone_number,
      address: myOrderIDvalue[myOrderIDvalue.length - 1]?.address,
      note: myOrderIDvalue[myOrderIDvalue.length - 1]?.note,
    },
    items: orderItems,
    installments: installments,
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // console.log(orderData);
    setIsLoading(true);

    axios(`${baseUrl}/tailor/pay-with-installment/`, {
      method: "POST",
      data: orderData,
    })
      .then((resp) => {
        console.log("Order response:", resp);
        if (resp.status === 201) {
          window.location.href = resp.data.order_url; // Redirect to payment URL
        }
      })
      .catch((error) => {
        console.error("Order creation failed", error);
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: t("orderCreate"),
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  

  return (
    <div className="info_modal_container">
      <div className="info_modal_card">
        <IoClose
          onClick={() => dispatch(closeTaksitModalFunc())}
          className="close_taksit_modal_icon"
        />
        {basket[0]?.sale_price ? (
                  <div className="installment_month" style={{padding:"0 30px 0 30px", textAlign:"center"}}>
                    <p>2 aylıq taksit ilə: <i>{(basket[0]?.sale_price / (1 - (1.33 * 2) / 100)).toFixed(2)} AZN</i></p>
                    <p>3 aylıq taksit ilə: <i>{(basket[0]?.sale_price / (1 - (1.33 * 3) / 100)).toFixed(2)} AZN</i></p>
                    <p>6 aylıq taksit ilə: <i>{(basket[0]?.sale_price / (1 - (1.33 * 6) / 100)).toFixed(2)} AZN</i></p>
                    <p>9 aylıq taksit ilə: <i>{(basket[0]?.sale_price / (1 - (1.33 * 9) / 100)).toFixed(2)} AZN</i></p>
                  </div>
                ) : (
                  <div className="installment_month" style={{padding:"0 30px 0 30px", textAlign:"center"}}>
                    <p>2 aylıq taksit ilə: <i>{(basket[0]?.price / (1 - (1.33 * 2) / 100)).toFixed(2)} AZN</i></p>
                    <p>3 aylıq taksit ilə: <i>{(basket[0]?.price / (1 - (1.33 * 3) / 100)).toFixed(2)} AZN</i></p>
                    <p>6 aylıq taksit ilə: <i>{(basket[0]?.price / (1 - (1.33 * 6) / 100)).toFixed(2)} AZN</i></p>
                    <p>9 aylıq taksit ilə: <i>{(basket[0]?.price / (1 - (1.33 * 9) / 100)).toFixed(2)} AZN</i></p>
                  </div>
                )}

        <form
          className="info_inputs info_modal_card_taksit"
          onSubmit={handleSubmit}
        >
          <select
            id="installments"
            name="installments"
            value={installments}
            onChange={(e) => setInstallments(e.target.value)}
          >
            <option value="2">2 {t("monthInstallment")}</option>
            <option value="3">3 {t("monthInstallment")}</option>
            <option value="6">6 {t("monthInstallment")}</option>
            <option value="9">9 {t("monthInstallment")}</option>
          </select>

          <button type="submit">{t("senOrder")}</button>
        </form>
      </div>
    </div>
  );
};

export default TaksitModalSelect;
