import React, { useEffect, useState } from "react";
import { BsFillPinMapFill } from "react-icons/bs";
import { HiOutlineMail } from "react-icons/hi";
import { FcPhone } from "react-icons/fc";
import { FcCalendar } from "react-icons/fc";
import axios from "axios";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { baseUrl } from "../base_url";
import { FiChevronUp } from "react-icons/fi";
import { useSelector } from "react-redux";
import Accordionİtems from "./modals/Accordionİtems";

const Contact = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const [name, setName] = useState("");
  const [email, setLastemail] = useState("");
  const [tel, setTel] = useState("");
  const [text, setText] = useState("");

  const nameChange = (e) => {
    setName(e.target.value);
  };
  const emailChange = (e) => {
    setLastemail(e.target.value);
  };
  const telChange = (e) => {
    setTel(e.target.value);
  };
  const textChange = (e) => {
    setText(e.target.value);
  };

  const messageSub = async (e) => {
    e.preventDefault();

    if (name === "" || email === "" || tel === "" || text === "") {
      Swal.fire({
        icon: "error",
        title: t("mesErr"),
        text: "",
        timer: 2000,
      });
    } else {
      setIsLoading(true);
      const data = {
        name: name,
        email: email,
        phone_number: tel,
        message: text,
      };
      axios
        .post(baseUrl + "/account/message-create/", data)
        .then((resp) => {
          setIsLoading(false);
          if (resp.status === 201) {
            Swal.fire({
              icon: "success",
              title: t("mesSucc"),
              text: "",
              timer: 2000,
            });

            setName("");
            setLastemail("");
            setTel("");
            setText("");
          }
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  };

  // useEffect(() => {
  //   window.scrollTo({
  //     top: 0,
  //   });
  // });

  const [isActive, setIsActive] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);

  const questionAnswer = useSelector((state) => state.Data.questionAnswer);
  const toggleAcc = (index) => {
    // Принимает индекс элемента
    setActiveIndex(activeIndex === index ? null : index);
  };

  // accordion two version

  const [openId, setOpenId] = useState(null);

  return (
    <section>
      <div className="contact_container">
        <h1>{t("contact")}</h1>
        <div className="contact_content">
          <div className="contact_left">
            <div className="contact_box">
              <BsFillPinMapFill className="box_icon" />
              <div>
                <h2>{t("adrName")}</h2>
                <p>{t("adress")}</p>
                <p>Hüquqi Ünvan: Səbayıl, Neftçi Qurban 50/52</p>
                <p>VÖEN: 1703097341</p>
              </div>
            </div>
            <div className="contact_box">
              <HiOutlineMail className="box_icon" />
              <div>
                <h2>Email</h2>
                <p>info@empowerwoman.az</p>
              </div>
            </div>
            <div className="contact_box">
              <FcCalendar className="box_icon" />
              <div>
                <h2>{t("workT")}</h2>
                <p>
                  {t("week")} <br />
                  {t("hours")}
                </p>
              </div>
            </div>
          </div>

          <form className="contact_form" onSubmit={messageSub}>
            <h2>{t("question")}</h2>
            <div className="contact_flex">
              <label htmlFor="name">
                <input
                  id="name"
                  value={name}
                  onChange={nameChange}
                  type="text"
                  placeholder={t("regName")}
                />
              </label>
            </div>
            <div className="contact_flex">
              <label htmlFor="phone">
                {" "}
                <input
                  id="phone"
                  value={tel}
                  onChange={telChange}
                  type="tel"
                  placeholder="Tel:"
                />
              </label>

              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={emailChange}
                required
              />
            </div>
            <textarea
              value={text}
              onChange={textChange}
              placeholder="Message"
              cols="30"
              rows="6"
            ></textarea>

            <button className="contact_btn">
              {isLoading ? <span className="loader"></span> : t("send")}
            </button>
          </form>
        </div>
      </div>
      <h2
        style={{
          fontFamily: "Montserrat, sans-serif",
          maxWidth: "870px",
          margin: "0 auto",
          marginBottom: "15px",
        }}
      >
        Sual - Cavab
      </h2>
      <ul className="accordion">
        {questionAnswer.map((faqItem, id) => {
          return (
            <Accordionİtems
              onClick={() => (id === openId ? setOpenId(null) : setOpenId(id))}
              faqItem={faqItem}
              isOpen={id === openId}
              openId={openId}
              ckey={id}
            />
          );
        })}
      </ul>
    </section>
  );
};

export default Contact;
