import React from "react";

const SearchResultMap = ({ data }) => {
  
  const test = () => {
    window.open(`/product/${data.id}`);
  };
  return (
    <div onClick={test}>
      <p className="open_search_list" >{data.translations.az.title}</p>
    </div>
  );
};

export default SearchResultMap;
