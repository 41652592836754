import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getMyFavoriteList, getRetrieveProduct } from "../action/MainAction";
import {
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TelegramShareButton,
  TelegramIcon,
  TwitterShareButton,
  TwitterIcon,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
} from "react-share";

import { addToCart } from "../redux/MainReducer";
import ProductCartBox from "./ProductCartBox";
import ColorsOfProduct from "./ColorsOfProduct";
import SizesOfProduct from "./SizesOfProduct";
import axios from "axios";
import Swal from "sweetalert2";
import DOMPurify from "dompurify";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import ImageGallery from "react-image-gallery";
import { useTranslation } from "react-i18next";
import i18n from "../18n";
import { baseUrl } from "../base_url";
import Loading from "./modals/Loading";

const ProductCart = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const currLang = i18n.language;

  const isLoading = useSelector((state) => state.Data.isLoading);

  const dispatch = useDispatch();
  const dataPr = useSelector((state) => state.Data.getRetriveProductValue);

  useEffect(() => {
    dispatch(getRetrieveProduct(id));
    dispatch(getMyFavoriteList());
  }, [dispatch, id]);

  const getMyFavoriteListValue = useSelector(
    (state) => state.Data.getMyFavoriteListValue
  );

  const checkItem = getMyFavoriteListValue.find(
    (data) => data?.product === dataPr?.id
  );

  const colorValue = useSelector((state) => state.Data.colorValue);
  const sizeValue = useSelector((state) => state.Data.sizeValue);
  const userAuth = JSON.parse(localStorage.getItem("userAuth"));
  const ACCESS_TOKEN = localStorage.getItem("ACCESS_TOKEN");

  const addRemoveFavorite = async (id) => {
    if (!ACCESS_TOKEN) {
      window.location.href = "/login";
    } else {
      try {
        if (!checkItem) {
          const data = {
            user: userAuth.id,
            product: id,
          };
          const headers = {
            Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
          };

          const resp = await axios.post(
            baseUrl + "/tailor/favourite-create/",
            data,
            { headers }
          );

          if (resp.status === 201) {
            dispatch(getMyFavoriteList());

            Swal.fire({
              position: "top-end",
              icon: "success",
              title: t("succFav"),
              showConfirmButton: false,
              timer: 1000,
            });
          }
        } else {
          const resp = await axios.delete(
            baseUrl +
              `/tailor/favourite-retrieve-update-delete/${checkItem.id}/`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
              },
            }
          );

          if (resp.status === 204) {
            dispatch(getMyFavoriteList());
          }
        }
      } catch (err) {
        console.error(err); // Логирование ошибки для отладки
      }
    }
  };

  const [imagesFromApi, setImagesFromApi] = useState([]);

  useEffect(() => {
    if (Object.keys(dataPr).length > 0) {
      const imagesData = dataPr.product_images.map((data) => ({
        original: data.image,
        thumbnail: data.image,
        loading: "lazy",
      }));

      setImagesFromApi(imagesData);
    }
  }, [dataPr]);

  const currUrlPage = `empowerwoman.az/product/${id}`;

  return (
    <>
      <div className="p_container">
        <div className="p_box">
          {isLoading ? (
            <Loading />
          ) : (
            <>
              <div className="images">
                <ImageGallery
                  items={imagesFromApi}
                  showPlayButton={false}
                  lazyLoad={true}
                />
              </div>

              <div className="p_content">
                <div className="product_title_category">
                  <h3>
                    {dataPr?.translations?.az?.title &&
                      dataPr?.translations[currLang]?.title}
                  </h3>
                  {
                    <i>
                      <span>
                        {dataPr.categories &&
                          dataPr.categories.length > 0 &&
                          dataPr.categories[0]?.translations?.az?.name &&
                          dataPr.categories[0]?.translations[currLang]?.name}
                      </span>
                    </i>
                  }
                </div>

                {dataPr?.sale_price ? (
                  <div>
                    <del className="p_price" style={{ borderBottom: "none" }}>
                      <span>
                        <i
                          style={{
                            color: "red",
                            fontSize: "2.1rem",
                          }}
                        >
                          {(dataPr.price * 1.015).toFixed(2)} AZN
                        </i>{" "}
                      </span>
                    </del>
                    <br />
                    <span className="p_price">
                      {(dataPr?.sale_price * 1.015).toFixed(2)} AZN
                    </span>
                  </div>
                ) : (
                  <span className="p_price">
                    {(dataPr?.price * 1.015).toFixed(2)} AZN
                  </span>
                )}
                {dataPr?.sale_price ? (
                  <div className="installment_month">
                    <p>2 aylıq taksit ilə: <i>{(dataPr?.sale_price / (1 - (1.33 * 2) / 100)).toFixed(2)} AZN</i></p>
                    <p>3 aylıq taksit ilə: <i>{(dataPr?.sale_price / (1 - (1.33 * 3) / 100)).toFixed(2)} AZN</i></p>
                    <p>6 aylıq taksit ilə: <i>{(dataPr?.sale_price / (1 - (1.33 * 6) / 100)).toFixed(2)} AZN</i></p>
                    <p>9 aylıq taksit ilə: <i>{(dataPr?.sale_price / (1 - (1.33 * 9) / 100)).toFixed(2)} AZN</i></p>
                  </div>
                ) : (
                  <div className="installment_month">
                    <p>2 aylıq taksit ilə: <i>{(dataPr?.price / (1 - (1.33 * 2) / 100)).toFixed(2)} AZN</i></p>
                    <p>3 aylıq taksit ilə: <i>{(dataPr?.price / (1 - (1.33 * 3) / 100)).toFixed(2)} AZN</i></p>
                    <p>6 aylıq taksit ilə: <i>{(dataPr?.price / (1 - (1.33 * 6) / 100)).toFixed(2)} AZN</i></p>
                    <p>9 aylıq taksit ilə: <i>{(dataPr?.price / (1 - (1.33 * 9) / 100)).toFixed(2)} AZN</i></p>
                  </div>
                )}

                <div
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      dataPr?.translations?.az?.characteristics &&
                        dataPr?.translations[currLang]?.characteristics
                    ),
                  }}
                ></div>
                <div>
                  <p className="color">{t("color")}:</p>
                  <ul className="p_color">
                    {dataPr?.colors &&
                      dataPr?.colors.map((data, i) => (
                        <ColorsOfProduct key={i} dataColor={data} />
                      ))}
                  </ul>
                </div>
                <div>
                  <p className="size">{t("size")}:</p>
                  <ul className="p_size">
                    {dataPr?.sizes &&
                      dataPr?.sizes.map((data, i) => {
                        return <SizesOfProduct key={i} dataSizes={data} />;
                      })}
                  </ul>
                </div>
                <div className="heart_add_to_basket">
                  <div
                    className="basket"
                    onClick={() => {
                      if (!sizeValue?.size || !colorValue?.color_code) {
                        Swal.fire({
                          position: "top-end",
                          icon: "error",
                          title: t("toBasBtnErr"),
                          showConfirmButton: false,
                          timer: 800,
                        });
                      } else {
                        dispatch(addToCart(dataPr));
                      }
                    }}
                  >
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3 1L1.5 3V10C1.5 10.2652 1.60536 10.5196 1.79289 10.7071C1.98043 10.8946 2.23478 11 2.5 11H9.5C9.76522 11 10.0196 10.8946 10.2071 10.7071C10.3946 10.5196 10.5 10.2652 10.5 10V3L9 1H3Z"
                        stroke="white"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M1.5 3H10.5"
                        stroke="white"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M8 5C8 5.53043 7.78929 6.03914 7.41421 6.41421C7.03914 6.78929 6.53043 7 6 7C5.46957 7 4.96086 6.78929 4.58579 6.41421C4.21071 6.03914 4 5.53043 4 5"
                        stroke="white"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <button className="add_to_card">{t("toBasBtn")}</button>
                  </div>
                  <div
                    className="heart"
                    onClick={() => addRemoveFavorite(dataPr.id)}
                  >
                    {checkItem ? <AiFillHeart /> : <AiOutlineHeart />}
                  </div>
                </div>

                <div className="social_media_share">
                  <FacebookShareButton url={currUrlPage}>
                    <FacebookIcon />
                  </FacebookShareButton>
                  <FacebookMessengerShareButton url={currUrlPage}>
                    <FacebookMessengerIcon />
                  </FacebookMessengerShareButton>
                  <WhatsappShareButton url={currUrlPage}>
                    <WhatsappIcon />
                  </WhatsappShareButton>
                  <TelegramShareButton url={currUrlPage}>
                    <TelegramIcon />
                  </TelegramShareButton>
                  <TwitterShareButton url={currUrlPage}>
                    <TwitterIcon />
                  </TwitterShareButton>
                </div>
              </div>
            </>
          )}
        </div>
        <ProductCartBox />
      </div>
    </>
  );
};

export default ProductCart;
