import axios from "axios";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { baseUrl } from "../base_url";
import { useTranslation } from "react-i18next";

const Payment = ({ setIsOpen }) => {
  const basket = useSelector((state) => state.Data.basket);
  const myOrderIDvalue = useSelector((state) => state.Data.myOrderIDvalue);

  const [isSuccessful, setIsSuccessful] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const {t} = useTranslation()

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const orderItemCreate = async (e) => {
    e.preventDefault();
    setIsLoader(true);

    const headers = {
      Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
    };

    try {
      for (const item of basket) {
        const data = {
          quantity: item.quantity,
          color: item.choise_color,
          size: item.choise_size,
          product: item.id,
          order: myOrderIDvalue[0].id,
        };

        await axios.post(baseUrl + "/tailor/orderitem-create/", data, { headers });
        await delay(1000); // Yalnız zəruridirsə saxlayın
      }

      setIsSuccessful(true);
      // Ödəmə nəticəsini yoxlayırıq
      await checkPaymentResult();

    } catch (error) {
      Swal.fire({
        icon: "error",
        title: t("payment1"), 
        text: t("payment2"),
        timer: 5000,
      });
    } finally {
      setIsLoader(false);
    }
  };

  // Ödəmə nəticəsini yoxlayan funksiya
  const checkPaymentResult = async () => {
    try {
      const result = await axios.post(baseUrl + "/tailor/payment-result/", null, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("ACCESS_TOKEN")}`,
        },
      });

      if (result.data.status === "approved") {
        window.location.href = baseUrl + "/tailor/payment-result/approved/";
      } else if (result.data.status === "canceled") {
        window.location.href = baseUrl + "/tailor/payment-result/canceled/";
      } else if (result.data.status === "declined") {
        window.location.href = baseUrl + "/tailor/payment-result/declined/";
      }
    } catch (error) {
      console.error("Ödəmə nəticəsini yoxlamaq mümkün olmadı.", error);
    }
  };

  if (isSuccessful) {
    Swal.fire({
      icon: "success",
      title: t("payment3"),
      text: "",
      timer: 5000,
    });
    // setTimeout(() => {
    //   localStorage.removeItem("basketList");
    //   window.location.href = "/";
    // }, 3000);
  }

  return (
    <div className="wrapper">
      <div className="payment">
        <div className="payment-logo">
          <img src="/images/credit-card.png" alt="" />
        </div>
        <h2>Ödəmə Şlüzü</h2>

        {isLoader ? (
          <form className="form_loader_wrapper">
            <div className="loader_payment"></div>
          </form>
        ) : (
          <form className="form" onSubmit={orderItemCreate}>
            <div className="card space icon-relative">
              <label className="label">Kartın nömrəsi</label>
              <input
                type="text"
                className="input"
                data-mask="0000 0000 0000 0000"
                placeholder="0000 0000 0000 0000"
              />
              <i className="far fa-credit-card"></i>
            </div>
            <div className="card-grp space">
              <div className="card-item icon-relative">
                <label className="label">Bitmə tarixi</label>
                <input
                  type="text"
                  name="expiry-data"
                  className="input"
                  placeholder="00 / 00"
                />
                <i className="far fa-calendar-alt"></i>
              </div>
              <div className="card-item icon-relative">
                <label className="label">CVV</label>
                <input
                  type="text"
                  className="input"
                  data-mask="000"
                  placeholder="000"
                />
                <i className="fas fa-lock"></i>
              </div>
            </div>
            <button type="submit" className="btn">
              Ödənişi Tamamla
            </button>
          </form>
        )}
      </div>
      <div className="info_xmark" onClick={() => setIsOpen(false)}>
        <i className="fa-solid fa-xmark"></i>
      </div>
    </div>
  );
};

export default Payment;
