import React from "react";
import Footer from "../components/Footer";
import LoginBox from "../components/LoginBox";
import Navbar from "../components/Navbar";
import { Helmet } from "react-helmet";

import { useTranslation } from "react-i18next";
import LoginBoxForget from "../components/LoginBoxForget";
import { useParams } from "react-router-dom";

const Login = () => {
  const { uid, token } = useParams();
  const {t}=useTranslation()
  return (
    <div>
      <Helmet>
        <title>{t("loginHead")}</title>
      </Helmet>
      <Navbar />
      <LoginBoxForget uid={uid} token={token}/>
      <Footer />
    </div>
  );
};

export default Login;
